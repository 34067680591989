import axios from 'axios'

axios.defaults.baseURL = 'https://www.clprocesos.cl/'

var message = {
    base: {
        language: "Language",
        menu: {
            slogan: "Applied\n Technology",
            home: "Home",
            products: "Products",
            services: "Services",
            brands: "Brands",
            about: "About us",
            contact: "Contact"
        },
        breadcrumbs: {
            home: "Home",
            news: "News",
            products: "Products",
            services: "Services",
            brands: "Brands",
            about: "About us",
            contact: "Contact"
        },
        carousel: {
            news: "News",
            loading: "Loading news...",
            all_news: "See all the news"
        },
        footer: {
            products: "Products",
            brands: "Brands",
            copyright: "All rights reserved"
        },
        home: {
            text_1: "Projects and services",
            text_d_1: "Our projects and services are focused on <br>the production and management processes in the <br>industry, we offer various services according to <br>each need.",
            text_b_1: "REQUEST FURTHER INFORMATION",
            text_2: "We respond effectively\n to the needs on-site",
            text_3: "Products",
            text_4: "Products with high quality standards"
        },
        contact: {
            address: "Address",
            phone: "Telephone",
            postal_code: "Postal Code",
            email: "Contact Email"
        },
        others: {
            visit: "Go to the website"
        }
    },
    products: null,
    about: null,
    services: null,
    brands: null,
    contact: null,
    carousel: null,
    news: null,
    status: {
        products: "not ok",
        about: "not ok",
        services: "not ok",
        brands: "not ok",
        contact: "not ok",
        carousel: "not ok",
        news: "not ok",
    },
}

axios.get('lang/en.json').then(response => {

    message.products = response.data.products
    message.products.btn_text = "Request more information"

    message.about = response.data.about
    message.services = response.data.services
    message.brands = response.data.brands
    message.contact = response.data.contact

    var res = response.data.carousel

    const carouselData = res.map(item => {
        const baseURL = process.env.VUE_APP_BASE_URL || 'https://clprocesos.cl/';
        //const baseURL = process.env.VUE_APP_BASE_URL || 'http://localhost:8080/';
        item.btn_text = 'SEE PRODUCTS';
        switch (item.title) {
            case "Weighing and Dosing":
                item.image_path = `${baseURL}` + 'images/Image-2.webp.jpg';
                item.description = "We offer you a range of products to meet your needs";
                break;
            case "DYNA Instruments":
                item.image_path = `${baseURL}` + 'images/Image.webp';
                item.description = "Measurement Technologies for Powder and Bulk Material Applications";
                break;
            case "Load Cells and Weighing Modules":
                item.image_path = `${baseURL}` + 'images/Image-1.webp';
                break;
            case "Weighing and Dosing":
                item.image_path = `${baseURL}` + 'images/Image-2.webp.jpg';
                item.description = "We offer you a range of products to meet your needs";
                break;
            case "Applied Technology":
                item.image_path = `${baseURL}` + 'images/Image-3.webp';
                item.description = "Cutting-edge technological solutions, supported by reliable and quality service.";
                break;
            case "On-Board Weighing for Mining Trucks":
                item.image_path = `${baseURL}` + 'images/Image-4.png';
                item.description = "For mining trucks, engineering specialized in weighing and automation.";
                break;

            default:
                break;
        }
        return item;
    })

    message.carousel = carouselData;

    message.carousel_products = {
        title: "Featured Products",
        subtitle: "With high quality standards",
        see_more_btn: "SEE ALL",
        products: {
            items: [
                {
                    title: "DYNA Instruments",
                    description: "Instrumentation for the detection and measurement of bulk solids.",
                    image_path: "/images/Background.webp",
                    btn_text: "Request more information",
                },
                {
                    title: "Class III Scales",
                    description: "Truck scales.",
                    image_path: "/images/Background-1.webp",
                    btn_text: "Request more information",
                },
                {
                    title: "SCAIME",
                    description: "Load cells and weighing modules.",
                    image_path: "/images/Background-2.webp",
                    btn_text: "Request more information",
                },
            ]
        }
    }

    message.contact_form = {
        title: "Do you have any questions? Contact us!",
        subtitle: "Free initial technical consultation",
        name_label: "Name *",
        surname_label: "Surname *",
        company_label: "Company *",
        phone_label: "Phone *",
        email_label: "E-mail *",
        product_label: "Product or service",
        message_label: "Message",
        agree_label: "I have read and agree with the personal data protection information. *",
        submit_btn: "Submit",
        required_fields_note: "All fields with * are required",
        success_message: "Form sent successfully!",
        error_message: "There was a problem submitting the form.",
    }

    message.slide_brands = {
        title: "Companies that trust us",
        subtitle: "With high quality standards",
        carouselLogo: [
            'images/brands/1.png',
            'images/brands/2.png',
            'images/brands/3.png',
            'images/brands/4.png',
            'images/brands/5.png',
            'images/brands/6.png',
            'images/brands/7.png',
            'images/brands/8.png',
            'images/brands/9.png',
            'images/brands/10.png',
            'images/brands/11.png',
            'images/brands/12.png',
            'images/brands/13.png',
            'images/brands/14.png',
            'images/brands/15.png',
            'images/brands/16.png',
            'images/brands/17.png',
            'images/brands/18.png',
            'images/brands/19.png',
            'images/brands/20.png',
            'images/brands/21.png',
            'images/brands/22.png',
            'images/brands/23.png',
            'images/brands/24.png',
            'images/brands/25.png'
        ],
    }

    message.footer = {
        menu_title: "Menu",
        contact_title: "Contact Us",
        send_btn: "Send",
        follow_us: "Follow Us",
        rights_reserved: "All rights reserved.",
        home: "Home",
        products: "Products",
        services: "Services",
        representations: "Representations",
        about_us: "About Us",
        email_placeholder: "Email",
        success_message: 'Email sent successfully!',
        error_message: 'There was an error sending the email. Please try again later.',
    };

    message.banner_services = {
        title: "Products and Services",
        subtitle: "Focused on production and management processes in the industry"
    };

    message.list_services = {
        title: "Some of the <strong>main services </strong> we offer:",
        btn_text: "More information",
        services: [
            {
                image: require('@/assets/images/Frame.png'),
                title: 'Engineering design and manufacturing',
                description: 'From steel structures to weighing systems',
            },
            {
                image: require('@/assets/images/Frame-6.png'),
                title: 'Civil Works',
                description: 'For truck scales and railway scales',
            },
            {
                image: require('@/assets/images/Frame-5.png'),
                title: 'Technical Inspection of Works',
                description: 'For weighing solutions',
            },
            {
                image: require('@/assets/images/Frame-4.png'),
                title: 'Instruments and Accessories',
                description: 'For truck scales and railway scales',
            },
            {
                image: require('@/assets/images/Frame-1.png'),
                title: 'Automatic Systems',
                description: 'For the calibration of weighmeters',
            },
            {
                image: require('@/assets/images/Frame-2.png'),
                title: 'Calibration Services',
                description: 'For truck scales, weighmeters, and weigh feeders',
            },
            {
                image: require('@/assets/images/Frame-3.png'),
                title: 'Technical Assistance',
                description: 'And commissioning of weighing and dosing systems',
            },
            {
                image: require('@/assets/images/Frame-10.png'),
                title: 'Maintenance Contracts',
                description: 'For scales',
            }
        ],
    };

    message.about_cards = {
        title: "Integration and development of weighing solutions",
        subtitle: "with products that meet high standards of quality",
        cards: [
            { title: 'Mission', text: 'The mission of <strong>CL PROCESOS S.A.</strong> is to deliver cutting-edge technological systems, supported by reliable and quality service.', image: '/images/bg_card_1.webp' },
            { title: 'Vision', text: '<strong>CL PROCESOS S.A.</strong> is a company made up of professionals with extensive experience, whose vision is to become a strategic ally for companies in any productive sector.', image: '/images/bg_card_2.webp' },
            { title: 'Milestones', text: 'Perseverance, appropriate application of technologies, innovation, focus on long-term relationships.', image: '/images/bg_card_3.webp' }
        ]
    };

    message.time_line_text = {
        title: "Important Milestones",
        desktop: '/images/time-line-en.webp',
        mobile: '/images/time-line-mobile-en.webp',
    };

    message.news = response.data.news

    message.status.products = "ok"
    message.status.about = "ok"
    message.status.services = "ok"
    message.status.brands = "ok"
    message.status.contact = "ok"
    message.status.carousel = "ok"
    message.status.news = "ok"
    message.status.carousel_products = "ok"
    message.status.contact_form = "ok"
    message.status.slide_brands = "ok"
    message.status.banner_services = "ok"
    message.status.list_services = "ok"
    message.status.about_cards = "ok"
    message.status.time_line_text = "ok"

})

export default { message }