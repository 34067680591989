<template>
  <div>
    <!-- Banner Principal -->
    <BannerServices />

    <!-- <v-row align="start" justify="center">
      <v-col cols="11" xl="8" lg="8" md="10" sm="11">
        <p v-if="$t('message.status.services') == 'ok'" :style="headerText">{{ $t("message.services.title") }}</p>
      </v-col>
    </v-row>

    <v-row align="start" justify="center">
      <v-col cols="11" xl="8" lg="8" md="10" sm="11">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="11" xl="8" lg="8" md="10" sm="11">
        <div v-if="$t('message.status.services') == 'ok'" :style="titleText" class="font-weight-light">
          {{ $t("message.services.subtitle") }}
        </div>
      </v-col>

      <v-col v-if="$t('message.status.services') == 'ok'" cols="11" xl="8" lg="8" md="10" sm="11">
        <ul v-for="(elements, i) in $t('message.services.elements')" :key="i">
          <li :style="descriptionText" class="font-weight-light pb-4">{{ elements.element }}</li>
        </ul>
      </v-col>
    </v-row> -->

    <!-- Servicios Principales -->
    <ServiceList />
  </div>
</template>

<script>
import BannerServices from '@/components/BannerServices.vue';
import ServiceList from '@/components/ServiceList.vue';

export default {
  name: 'Services',
  components: {
    BannerServices,
    ServiceList,
  },
  data: () => ({}),
  created() {},
  methods: {},
  computed: {
    headerText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "font-size: 18px; text-align: justify;"
        case 'sm': return "font-size: 30px; text-align: center;"
        case 'md': return "font-size: 30px; text-align: center;"
        case 'lg': return "font-size: 40px; text-align: center;"
        case 'xl': return "font-size: 40px; text-align: center;"
      }
    },
    titleText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "font-size: 18px;"
        case 'sm': return "font-size: 28px;"
        case 'md': return "font-size: 28px;"
        case 'lg': return "font-size: 28px;"
        case 'xl': return "font-size: 28px;"
      }
    },
    descriptionText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "font-size: 16px; text-align:justify;"
        case 'sm': return "font-size: 20px;"
        case 'md': return "font-size: 20px;"
        case 'lg': return "font-size: 20px;"
        case 'xl': return "font-size: 20px;"
      }
    },
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-size: 2rem;
}

.subtitle-1 {
  font-size: 1.25rem;
}
</style>
