<template>
  <v-container class="ma-0 py-14 px-4 bg-white" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <h2 class="text-center font-weight-c" v-html="$t('message.list_services.title')"></h2>
      </v-col>
      <v-col cols="11" xl="8" lg="8" md="10" sm="11">
        <v-row justify="center">
          <v-col cols="12" md="3" v-for="(service, index) in $t('message.list_services.services')" :key="index">
            <v-card class="mx-auto" max-width="344" height="100%">
              <v-card-title>
                <v-img :src="service.image" contain height="100"></v-img>
              </v-card-title>
              <v-card-subtitle class="pb-0 bold-title">{{ service.title }}</v-card-subtitle>
              <v-card-text>
                {{ service.description }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-4">
          <v-col cols="auto">
            <v-btn color="buttom-more c-black" @click="moreInfo">{{$t('message.list_services.btn_text')}}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ServiceList',
  data() {
    return {
      services: [
        {
          image: require('@/assets/images/Frame.png'),
          title: 'Ingeniería diseño y fabricación',
          description: 'De estructuras de acero para sistemas de pesaje',
        },
        {
          image: require('@/assets/images/Frame-6.png'),
          title: 'Obras civiles',
          description: 'Para básculas de camiones y para básculas de FFCC',
        },
        {
          image: require('@/assets/images/Frame-5.png'),
          title: 'Inspección técnica de obras',
          description: 'Para soluciones de pesaje',
        },
        {
          image: require('@/assets/images/Frame-4.png'),
          title: 'Instrumentos y accesorios',
          description: 'Para básculas de camiones y de ferrocarriles',
        },
        {
          image: require('@/assets/images/Frame-1.png'),
          title: 'Sistemas automáticos',
          description: 'Para calibración de pesómetros',
        },
        {
          image: require('@/assets/images/Frame-2.png'),
          title: 'Servicios de calibración',
          description: 'De básculas de camiones, pesómetros y alimentadores pesométricos',
        },
        {
          image: require('@/assets/images/Frame-3.png'),
          title: 'Asistencia técnica',
          description: 'Y puesta en servicio de sistemas de pesaje y dosificación',
        },
        {
          image: require('@/assets/images/Frame-10.png'),
          title: 'Contratos de mantenimiento',
          description: 'De básculas',
        },
      ],
    };
  },
  methods: {
    moreInfo() {
      this.$router.push({ name: 'contact' });
    },
  },
};
</script>

<style scoped>
.mt-4 {
  margin-top: 1.5rem;
}

.font-weight-c {
  font-size: 2rem;
  font-weight: 400;
}

.v-card {
  text-align: center;
  padding: 20px;
}

.v-img {
  margin-bottom: 10px;
}

.v-card-subtitle {
  font-size: 1.125rem;
  font-weight: 500;
}

.bg-white {
  background-color: #fff;
}

.buttom-more{
  background-color: #00BCD4 !important;
  color: black;
}

.bold-title {
  font-weight: 700;
  color: black !important;
  margin-top: 8px;
  margin-bottom: 12px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  border: none;
  box-shadow: none;
}

@media only screen and (max-width: 640px) {
  .row {
    margin: 0px;

  }

  .v-card{
    padding: 0px;
  }

}
</style>