<template>
    <v-container>
        <v-row
            align="center"
            no-gutters
            style="height: 300px"
        >
            <v-col align="center">
                <p class="text-h2">Error 404</p>
                <p class="text-h5 font-weight-light">Página no encontrada</p>
                <p class="text-h6 font-weight-light"><router-link style="text-decoration: none !important" to="/">Volver a Inicio</router-link></p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
    }),
}
</script>
