<template>
    <v-container v-if="$t('message.status.contact') == 'ok'" class="ma-0 py-14" fluid>
        <v-row class="mx-2 pb-4"
         v-for="(data, i) in $t('message.contact')"
                    :key="i">
            <v-col cols="12" xl="6" lg="6" sm="12">
                <v-card
                    
                    elevation="3"
                    class="mx-auto grey lighten-5"
                >
                    <v-card-text>
                        <p :class="mapTitle" class="px-4 pt-4 text--primary font-weight-light">
                            {{ data.title }}
                        </p>
                        <p :class="mapSubtitle" class="px-4 font-weight-light">{{ data.subtitle }}</p>
                        <div class="pa-4">
                            <iframe
                                :src="data.map"
                                width="100%"
                                :height="mapHeight"
                                frameborder="0"
                                style="border:0"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" xl="6" lg="6" sm="12" >

                <p class="font-weight-light text-h5">
                    {{ $t('message.base.contact.address') }}
                </p>
                <v-divider class="pb-2"></v-divider>

                <p class="font-weight-light text-h6">{{ data.geolocation }}</p>

                <p class="font-weight-light text-h5">
                    {{ $t('message.base.contact.phone') }}
                </p>

                <v-divider class="pb-2"></v-divider>

                <div class="font-weight-light text-subtitle-1">
                    {{ data.phone_1 }}
                </div>
                <div class="font-weight-light text-subtitle-1">
                    {{ data.phone_2 }}
                </div>
                <div class="font-weight-light text-subtitle-1">
                    {{ data.phone_3 }}
                </div>

                <p class="font-weight-light text-h5 pt-4">
                    {{ $t('message.base.contact.postal_code') }}
                </p>

                <v-divider class="pb-2"></v-divider>

                <p class="font-weight-light text-h6">{{ data.postal_code }}</p>

                <p class="font-weight-light text-h5">
                    {{ $t('message.base.contact.email') }}
                </p>

                <v-divider class="pb-2"></v-divider>

                <p class="font-weight-light text-h6">{{ data.email }}</p>

            </v-col>
        </v-row>
		<!-- <ContactForm /> -->

    </v-container>

</template>

<script>
import ContactForm from "../components/ContactForm.vue";

export default {
    name: 'Contact',
    components: {
		ContactForm,
	},
    data: () => ({}),
    created() {},
    methods: {},
    computed: {
        mapHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 250
                case 'sm': return 350
                case 'md': return 450
                case 'lg': return 450
                case 'xl': return 450
                }
        },
        mapTitle () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "text-h4"
                case 'sm': return "text-h4"
                case 'md': return "text-h3"
                case 'lg': return "text-h3"
                case 'xl': return "text-h3"
            }
        },
        mapSubtitle () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "text-h6"
                case 'sm': return "text-h6"
                case 'md': return "text-h5"
                case 'lg': return "text-h5"
                case 'xl': return "text-h5"
            }
        },
    }
}
</script>

