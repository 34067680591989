<template>
<v-navigation-drawer
      v-model="localDrawer"
      absolute
      temporary
      style="position:fixed; top:0; left:0; overflow-y:scroll;"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
        >
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $t('message.base.menu.home') }} </v-list-item-title>
          </v-list-item>

          <v-list-item to="/products">
            <v-list-item-icon>
              <v-icon>mdi-bookmark</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $t('message.base.menu.products') }} </v-list-item-title>
          </v-list-item>

          <v-list-item to="/services">
            <v-list-item-icon>
              <v-icon>mdi-bookmark</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $t('message.base.menu.services') }} </v-list-item-title>
          </v-list-item>

          <v-list-item to="/brands">
            <v-list-item-icon>
              <v-icon>mdi-bookmark</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $t('message.base.menu.brands') }} </v-list-item-title>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon>mdi-bookmark</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $t('message.base.menu.about') }} </v-list-item-title>
          </v-list-item>

          <v-list-item to="/contact">
            <v-list-item-icon>
              <v-icon>mdi-bookmark</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $t('message.base.menu.contact') }} </v-list-item-title>
          </v-list-item>


         <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t('message.base.language') }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          @click="changeLanguage(child.title)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ child.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    props: {
    drawer: {
     type: Boolean,
    }
  },
  data: () => ({
      localDrawer: false,
      setLocale: null,
      items: [
        {
          action: 'mdi-earth',
          active: true,
          items: [
            { title: 'Español' },
            { title: 'English' },
            //{ title: '日本語' },
          ],
          title: 'Language',
        },
      ],
  }),
  methods: {
        changeLanguage(lang) {
            if (lang == 'Español') {
                this.$i18n.locale = 'es'
            }
            if (lang == 'English') {
                this.$i18n.locale = 'en'
            }
            if (lang == '日本語') {
                this.$i18n.locale = 'ja'
            }
        },
  },
  watch: {
      drawer: function() {
          this.localDrawer = this.drawer
      },
      localDrawer: function() {
          this.$emit("status", this.localDrawer)
      }
  },
}
</script>
