<template>
  <v-container class="pa-14">
    <v-row v-if="$t('message.status.products') == 'ok'" justify="center">
      <v-col cols="12" xl="3" lg="4" md="6" sm="6" v-for="(product, i) in $t('message.products')" :key="i">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto b-custom" max-width="600" height="100%"
            style="display: flex; flex-direction: column; justify-content: space-between;">
            <v-img :aspect-ratio="1" :src="'https://clprocesos.cl/public/images/' + product.image_path">
              <v-expand-transition>
                <div v-if="hover" :class="descriptionText"
                  class="d-flex transition-fast-in-fast-out cyan darken-1 v-card--reveal font-weight-light white--text"
                  style="height: 100%; font-size:12px;">
                  <p class="pa-8 ">{{ product.description }}</p>
                </div>
              </v-expand-transition>
            </v-img>

            <v-card-text class="pt-6" style="position: relative;">
              <div :class="subtitleText" class="mb-2 t-text-1">
                {{ product.subtitle }}
              </div>
              <h3 :class="titleText" class="font-weight black--text mb-2">
                {{ product.title }}
              </h3>
            </v-card-text>

            <v-card-actions class="self-center">
              <v-btn color="cyan darken-1" class="btn-custom" to="/contact">
                {{ $t('message.products.btn_text') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  created() { },
  methods: {},
  computed: {
    titleText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "text-h5"
        case 'sm': return "text-h4"
        case 'md': return "text-h4"
        case 'lg': return "text-h4"
        case 'xl': return "text-h4"
      }
    },
    subtitleText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "text-subtitle-1"
        case 'sm': return "text-h6"
        case 'md': return "text-h6"
        case 'lg': return "text-h6"
        case 'xl': return "text-h6"
      }
    },
    descriptionText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "text-body-2"
        case 'sm': return "text-h6"
        case 'md': return "text-h6"
        case 'lg': return "text-h6"
        case 'xl': return "text-h5"
      }
    },
    iconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return false
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
    },
  }
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .9;
  position: absolute;
  width: 100%;
}

.b-custom {
  box-shadow: none !important;
  border: solid 1px #D7D9DB !important;
}

.self-center {
  align-self: center;
}

.btn-custom {
  padding: 20px;
  color: black;
  margin-top: auto;
  margin-bottom: 20px;
}

.v-btn {
  border-radius: 0px;
}

.v-image {
  max-height: 330px;
}

.t-text-1 {
  font-size: 12px !important;
  color: black !important;
  font-weight: 500 !important;
}

.v-application .text-h4 {
  font-size: 16px !important;
}
</style>
