<template>
    <v-container fluid class="ma-0 pa-0" style="background: white;">
        <v-row justify="center">
            <div class="text-container py-10" style="text-align: center;">
                <h1>{{ $t('message.slide_brands.title') }}</h1>
                <h3>{{ $t('message.slide_brands.subtitle') }}</h3>
            </div>

            <v-col cols="12" sm="12" md="12" lg="12" class="my-0 py-0">
                <v-carousel v-if="$t('message.status.carouselLogo') == 'ok'" cycle :height="height" hide-delimiters
                    show-arrows-on-hover class="carousel-pagination" loop>
                    <v-carousel-item v-for="chunk in chunks" :key="chunk[0]" eager>
                        <v-row class="fill-height" align="center" justify="center">
                            <v-col v-for="(slide, i) in chunk" :key="i" cols="12" sm="6" md="4" lg="2"
                                class="text-center">
                                <div>
                                    <img :src="slide" alt="Logo" class="logo-image">
                                </div>
                            </v-col>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            height: 200,
            itemsPerView: 5,
        };
    },
    computed: {
        chunks() {
            const logos = this.$t('message.slide_brands.carouselLogo');
            const chunkSize = this.itemsPerView;
            const chunks = [];

            for (let i = 0; i < logos.length; i += chunkSize) {
                let chunk = logos.slice(i, i + chunkSize);

                // Si el chunk no está completo, rellenarlo con imágenes al principio
                if (chunk.length < chunkSize) {
                    chunk = [...chunk, ...logos.slice(0, chunkSize - chunk.length)];
                }

                chunks.push(chunk);
            }

            return chunks;
        }
    }
    ,
    mounted() {
        this.updateItemsPerView();
        window.addEventListener('resize', this.updateItemsPerView);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateItemsPerView);
    },
    methods: {
        updateItemsPerView() {
            const width = window.innerWidth;
            if (width >= 1200) {
                this.itemsPerView = 5;
            } else if (width >= 992) {
                this.itemsPerView = 4;
            } else if (width >= 768) {
                this.itemsPerView = 3;
            } else if (width >= 576) {
                this.itemsPerView = 2;
            } else {
                this.itemsPerView = 1;
            }
        }
    }
};
</script>

<style scoped>
.logo-image {
    width: 140px;
    height: auto;
    background-color: #EDEDED;
    padding: 13px;
    border-radius: 22px;
}

.carousel-pagination .v-window__controls {
    bottom: -30px;
    /* Ajusta este valor según la ubicación deseada de los puntos */
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.carousel-pagination .v-window__control {
    background-color: #000;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0 4px;
}

.carousel-pagination .v-window__control--active {
    background-color: #26C6DA;
}


@media only screen and (max-width: 640px) {
    .row {
        margin: 0px;
    }
}
</style>
