<template>
  <div>
    <WideImage imageSrc="/images/brand_banner.webp" />
    <MainTextAndCards :title="$t('message.about_cards.title')" :subtitle="$t('message.about_cards.subtitle')"
      :cards="$t('message.about_cards.cards')" />
    <TitleAndTimeline :title="$t('message.time_line_text.title')" :desktopImage="$t('message.time_line_text.desktop')"
      :mobileImage="$t('message.time_line_text.mobile')" />
  </div>
</template>

<script>
import WideImage from '../components/WideImage.vue';
import MainTextAndCards from '../components/MainTextAndCards.vue';
import TitleAndTimeline from '../components/TitleAndTimeline.vue';

export default {
  components: {
    WideImage,
    MainTextAndCards,
    TitleAndTimeline
  },
  data() {
    return {
      cards: [
        { title: 'Misión', text: 'La misión de CL PROCESOS S.A. es la entrega de sistemas tecnológicos de punta, apoyando con un servicio confiable y de calidad.', image: '/images/bg_card_1.webp' },
        { title: 'Visión', text: 'CL PROCESOS S.A. es una compañía compuesta por profesionales con una vasta experiencia, cuya visión es convertirse en un aliado estratégico para las empresas en cualquier rubro productivo.', image: '/images/bg_card_2.webp' },
        { title: 'Hitos', text: 'CL PROCESOS S.A. es una empresa dedicada al suministro y/o integración de soluciones tecnológicas de equipos y/o ingeniería especializada en pesaje y automatización.', image: '/images/bg_card_3.webp' }
      ]
    };
  }
}
</script>

<style scoped>
/* Aquí puedes agregar estilos globales si es necesario */
</style>