<template>
  <v-container class="pa-14">
    <v-row justify="center">
      <v-col cols="12" xl="3" lg="4" md="6" sm="6" v-for="(product, i) in products"
        v-show="product.category == $route.params.id" :key="i">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto b-custom"  max-width="600" height="100%">
            <v-img :aspect-ratio="1" :src="product.image">

            </v-img>
            <v-card-text class="pt-6" style="position: relative;">
              <v-dialog v-model="product.dialog" max-width="700">
                <v-card>
                  <v-toolbar color="cyan darken-1" class="white--text text-subtitle-1">{{ product.brand }} {{
                    product.title }}
                  </v-toolbar>
                  <v-card-title class="text-h5">
                    {{ product.title }}
                  </v-card-title>

                  <v-img contain max-height="300" :src="product.image">

                  </v-img>

                  <v-card-text style="text-align: justify;" class="pt-4">
                    {{ product.description }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red" text @click="product.dialog = false">
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn absolute color="cyan darken-1" class="white--text" fab right :small="iconSize" top
                @click.stop="product.dialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <h3 :class="title" class="font-weight-light black--text pt-2 mb-2">
                {{ product.title }}
              </h3>
            </v-card-text>
          </v-card>
        </v-hover> </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: "",
    categories: [],
    products: [],
  }),
  created() {
    this.getEntries()
  },
  methods: {
    includeCategory() {
      if (!this.categories.includes(this.$route.params.id)) {
        this.$router.push({ path: '@/*' })
      }
    },
    async getEntries() {
      try {
        const entriesDB = await this.axios.get('wp-json/wl/v1/products')

        await entriesDB.data.forEach(element => {
          let item = {}
          item.title = element.title
          item.brand = element.brand
          item.description = element.description
          item.image = element.image
          item.category = element.category[0].post_title
          item.dialog = false
          this.products.push(item)
          this.categories.push(item.category)
        })

        this.includeCategory()
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    title() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "text-h6"
        case 'sm': return "text-h6"
        case 'md': return "text-h5"
        case 'lg': return "text-h5"
        case 'xl': return "text-h5"
      }
    },
    iconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return false
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
    },
  }
}
</script>

<style scoped>
.b-custom {
  box-shadow: none;
  border-color: #D7D9DB !important;
}
</style>