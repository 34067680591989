import axios from 'axios'

axios.defaults.baseURL = 'https://clprocesos.cl/clprocesos-api/api/v1/'

var message = {
    base: {
        language: "Idioma",
        menu: {
            slogan: "Tecnología\n Aplicada",
            home: "Inicio",
            products: "Productos",
            services: "Servicios",
            brands: "Representaciones",
            about: "Perfil de empresa",
            contact: "Contacto",
        },
        breadcrumbs: {
            home: "Inicio",
            news: "Noticias",
            products: "Productos",
            services: "Servicios",
            brands: "Representaciones",
            about: "Perfil de empresa",
            contact: "Contacto",
        },
        carousel: {
            news: "Novedades",
            loading: "Cargando novedades...",
            all_news: "Ver todas las noticias",
        },
        footer: {
            products: "Productos",
            brands: "Representaciones",
            copyright: "Todos los derechos reservados",
        },
        home: {
            text_1: "Proyectos y servicios",
            text_d_1: "Nuestros proyectos y servicios están enfocados a <br>los procesos productivos y de gestión en la <br>industria, ofrecemos diversos servicios acordes a <br>cada necesidad.",
            text_b_1: "SOLICITAR MÁS INFORMACIÓN",
            text_2: "Respondemos a las\n necesidades in situ y en detalle",
            text_3: "Productos",
            text_4: "Productos que cuentan con altos estandares de calidad",

        },
        contact: {
            address: "Dirección",
            phone: "Teléfonos",
            postal_code: "Código Postal",
            email: "Mail de Contacto",
        },
        others: {
            visit: "Visitar Web",
        },
    },
    products: null,
    about: null,
    services: null,
    brands: null,
    contact: null,
    carousel: null,
    news: null,
    carousel_products: {
        products: {
            title: "Productos destacados",
            subtitle: "Con altos estándares de calidad",
            see_more_btn: "VER TODOS",
            items: [
                {
                    title: "DYNA Instruments",
                    description: "Instrumentación para la detección y medición de sólidos a granel.",
                    image_path: "/images/Background.webp",
                    btn_text: "Solicitar más información",
                },
                {
                    title: "Básculas Clase III",
                    description: "Básculas para camiones.",
                    image_path: "/images/Background-1.webp",
                    btn_text: "Solicitar más información",
                },
                {
                    title: "SCAIME",
                    description: "Celdas de carga y módulos de pesaje.",
                    image_path: "/images/Background-2.webp",
                    btn_text: "Solicitar más información",
                },

            ]
        }
    },
    status: {
        products: "not ok",
        about: "not ok",
        services: "not ok",
        brands: "not ok",
        contact: "not ok",
        carousel: "not ok",
        news: "not ok",
        carousel_products: "ok",
    },
}

async function product() {
    try {
        const res = await axios.get('product')
        message.products = res.data
        message.products.btn_text = "Solicitar más información"
        message.status.products = "ok"
    } catch (error) {
        const {
            status,
            statusText
        } = error.response
        console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
}

async function about() {
    try {
        const res = await axios.get('about')
        message.about = res.data[0]
        message.status.about = "ok"
    } catch (error) {
        const {
            status,
            statusText
        } = error.response
        console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
}

async function service() {
    try {
        const res = await axios.get('service')
        message.services = res.data[0]
        message.status.services = "ok"
    } catch (error) {
        const {
            status,
            statusText
        } = error.response
        console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
}

async function brand() {
    try {
        const res = await axios.get('brand')
        message.brands = res.data
        message.status.brands = "ok"
    } catch (error) {
        const {
            status,
            statusText
        } = error.response
        console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
}

async function contact() {
    try {
        const res = await axios.get('contact')
        message.contact = res.data
        message.status.contact = "ok"
    } catch (error) {
        const {
            status,
            statusText
        } = error.response
        console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
}

async function carousel() {
    try {
        const res = await axios.get('carousel')
        message.status.carousel = "ok"
        const carouselData = res.data.map(item => {
            const baseURL = process.env.VUE_APP_BASE_URL || 'https://clprocesos.cl/';
            //const baseURL = process.env.VUE_APP_BASE_URL || 'http://localhost:8080/';
            item.btn_text = 'VER PRODUCTOS';
            switch (item.title) {
                case "Pesaje y dosificación":
                    item.image_path = `${baseURL}` + 'images/Image-2.webp.jpg';
                    item.description = "Te ofrecemos una gama de productos para cubrir tus necesidades";
                    break;
                case "DYNA Instruments":
                    item.image_path = `${baseURL}` + 'images/Image.webp';
                    item.description = "Tecnologías de medición para aplicaciones de polvo y materiales a granel";
                    break;
                case "Celdas de carga y soluciones de pesaje":
                    item.image_path = `${baseURL}` + 'images/Image-1.webp';
                    break;
                case "Pesaje y dosificación":
                    item.image_path = `${baseURL}` + 'images/Image-2.webp.jpg';
                    item.description = "Te ofrecemos una gama de productos para cubrir tus necesidades";
                    break;
                case "Tecnología Aplicada":
                    item.image_path = `${baseURL}` + 'images/Image-3.webp';
                    item.description = "Soluciones tecnológicas de punta, apoyado con un servicio confiable y de calidad.";
                    break;
                case "Pesaje on-board para camiones mineros":
                    item.image_path = `${baseURL}` + 'images/Image-4.png';
                    item.description = "Para camiones mineros, ingeniería especializada en pesaje y automatización.";
                    break;

                default:
                    break;
            }
            return item;
        })

        message.carousel = carouselData;

    } catch (error) {
        const {
            status,
            statusText
        } = error.response
        console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
}

async function carouselLogo() {
    try {
        // const res = await axios.get('carousel')
        message.status.carouselLogo = "ok";
        const carouselData = [
            '/images/negocios-internacionales1.png',
            '/images/negocios-internacionales1.png',
            '/images/negocios-internacionales1.png',
            '/images/negocios-internacionales1.png',
            '/images/negocios-internacionales1.png',
        ];

        message.carouselLogo = carouselData;

    } catch (error) {
        const {
            status,
            statusText
        } = error.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
    }
}

async function blog() {
    try {
        const res = await axios.get('blog')
        message.news = res.data
        message.status.news = "ok"
    } catch (error) {
        const {
            status,
            statusText
        } = error.response
        console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
}

async function productsPrincipal() {
    message.carousel_products = {
        title: "Productos destacados",
        subtitle: "Con altos estándares de calidad",
        see_more_btn: "VER TODOS",
        products: {
            items: [
                {
                    title: "DYNA Instruments",
                    description: "Instrumentación para la detección y medición de sólidos a granel.",
                    image_path: "/images/Background.webp",
                    btn_text: "Solicitar más información",
                },
                {
                    title: "Básculas Clase III",
                    description: "Básculas para camiones.",
                    image_path: "/images/Background-1.webp",
                    btn_text: "Solicitar más información",
                },
                {
                    title: "SCAIME",
                    description: "Celdas de carga y módulos de pesaje.",
                    image_path: "/images/Background-2.webp",
                    btn_text: "Solicitar más información",
                },

            ]
        }
    }
    message.status.carousel_products = "ok"
}

async function contactForm() {
    message.contact_form = {
        title: "¿Tienes alguna duda? ¡Contáctanos!",
        subtitle: "Asesoramiento técnico inicial gratuito",
        name_label: "Nombre *",
        surname_label: "Apellido *",
        company_label: "Empresa *",
        phone_label: "Teléfono *",
        email_label: "E-mail *",
        product_label: "Producto o servicio",
        message_label: "Mensaje",
        agree_label: "He leído y estoy de acuerdo con la información sobre protección de datos personales. *",
        submit_btn: "Enviar",
        required_fields_note: "Todos los campos con * son obligatorios",
        success_message: "Formulario enviado exitosamente!",
        error_message: "Hubo un problema al enviar el formulario.",
    }

    message.status.contact_form = "ok"
}

async function slideBrands() {
    message.slide_brands = {
        title: "Empresas que confían en nosotros",
        subtitle: "Con altos estándares de calidad",
        carouselLogo: [
            'images/brands/1.png',
            'images/brands/2.png',
            'images/brands/3.png',
            'images/brands/4.png',
            'images/brands/5.png',
            'images/brands/6.png',
            'images/brands/7.png',
            'images/brands/8.png',
            'images/brands/9.png',
            'images/brands/10.png',
            'images/brands/11.png',
            'images/brands/12.png',
            'images/brands/13.png',
            'images/brands/14.png',
            'images/brands/15.png',
            'images/brands/16.png',
            'images/brands/17.png',
            'images/brands/18.png',
            'images/brands/19.png',
            'images/brands/20.png',
            'images/brands/21.png',
            'images/brands/22.png',
            'images/brands/23.png',
            'images/brands/24.png',
            'images/brands/25.png'
        ],
    }

    message.status.slide_brands = "ok"
}

async function footer() {
    message.footer = {
        menu_title: "Menú",
        contact_title: "Contáctanos",
        send_btn: "Enviar",
        follow_us: "Síguenos en",
        rights_reserved: "Todos los derechos reservados.",
        home: "Inicio",
        products: "Productos",
        services: "Servicios",
        representations: "Representaciones",
        about_us: "Nosotros",
        email_placeholder: "Correo",
        success_message: '¡Correo enviado exitosamente!',
        error_message: 'Hubo un error al enviar el correo. Por favor, intenta nuevamente más tarde.',
    };

    message.status.slide_brands = "ok"
}

async function bannerServices() {
    message.banner_services = {
        title: "Productos y Servicios",
        subtitle: "Enfocados a los procesos productivos y de gestión en la industria"
    };

    message.status.banner_services = "ok"
}

async function listServices() {
    message.list_services = {
        title: "Algunos de los <strong> servicios principales </strong> que ofrecemos:",
        btn_text: "Más información",
        services: [
            {
                image: require('@/assets/images/Frame.png'),
                title: 'Ingeniería diseño y fabricación',
                description: 'De estructuras de acero para sistemas de pesaje',
            },
            {
                image: require('@/assets/images/Frame-6.png'),
                title: 'Obras civiles',
                description: 'Para básculas de camiones y para básculas de FFCC',
            },
            {
                image: require('@/assets/images/Frame-5.png'),
                title: 'Inspección técnica de obras',
                description: 'Para soluciones de pesaje',
            },
            {
                image: require('@/assets/images/Frame-4.png'),
                title: 'Instrumentos y accesorios',
                description: 'Para básculas de camiones y de ferrocarriles',
            },
            {
                image: require('@/assets/images/Frame-1.png'),
                title: 'Sistemas automáticos',
                description: 'Para calibración de pesómetros',
            },
            {
                image: require('@/assets/images/Frame-2.png'),
                title: 'Servicios de calibración',
                description: 'De básculas de camiones, pesómetros y alimentadores pesométricos',
            },
            {
                image: require('@/assets/images/Frame-3.png'),
                title: 'Asistencia técnica',
                description: 'Y puesta en servicio de sistemas de pesaje y dosificación',
            },
            {
                image: require('@/assets/images/Frame-10.png'),
                title: 'Contratos de mantenimiento',
                description: 'De básculas',
            },
        ],
    };

    message.status.list_services = "ok"
}

async function about_cards() {
    message.about_cards = {
        title: "Integración y desarrollo de soluciones para pesaje",
        subtitle: "con productos que cuentan con altos estándares de calidad",
        cards: [
            { title: 'Misión', text: 'La misión de <strong> CL PROCESOS S.A.</strong> es la entrega de sistemas tecnológicos de punta, apoyando con un servicio confiable y de calidad.', image: '/images/bg_card_1.webp' },
            { title: 'Visión', text: '<strong>CL PROCESOS S.A.</strong> es una compañía compuesta por profesionales con una vasta experiencia, cuya visión es convertirse en un aliado estratégico para las empresas en cualquier rubro productivo.', image: '/images/bg_card_2.webp' },
            { title: 'Valores', text: 'Perseverancia, aplicación apropiada de las tecnologías, innovación, enfoque en las relaciones de largo plazo.', image: '/images/bg_card_3.webp' }
        ]
    };

    message.status.about_cards = "ok"
}

async function time_line_text() {
    message.time_line_text = {
        title: "Hitos Importantes",
        desktop: '/images/time-line.webp',
        mobile: '/images/time-line-mobile-2.webp',
    };

    message.status.time_line_text = "ok"
}

blog()
carousel()
carouselLogo()
product()
about()
service()
brand()
contact()
productsPrincipal()
contactForm()
slideBrands()
footer()
bannerServices()
listServices()
about_cards()
time_line_text()

export default { message }