<template>
  <v-container class="pa-14">
    <v-row v-if="$t('message.status.brands') == 'ok'" justify="center">
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" v-for="(brand, i) in $t('message.brands')" :key="i">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto d-flex flex-column" color="grey lighten-5" max-width="600" height="100%"
            style="position: relative;">
            <v-img :src="'https://clprocesos.cl/public/images/' + brand.image_path" aspect-ratio="1" contain></v-img>

            <v-expand-transition>
              <div v-if="hover"
                class="d-flex transition-fast-in-fast-out cyan darken-1 v-card--reveal font-weight-bold white--text"
                style="position: absolute; top: 0; left: 0; right: 0; bottom: 50px; display: flex; align-items: center; justify-content: center;">
                <p class="pa-8 text-center">{{ brand.description }}
                  <br>
                  <br>
                  <v-btn v-if="brand.url != null" class="bg-white-text" text :href="brand.url || ''" target="_blank">
                    {{ $t('message.base.others.visit') }}
                  </v-btn>
                </p>
                
              </div>

            </v-expand-transition>

            <v-card-text class="pt-6 flex-grow-1 d-flex flex-column justify-content-end">
              <v-card-title :class="titleText" class="font-weight-light title-brand black--text">
                {{ brand.title }}
              </v-card-title>
              <v-card-subtitle :class="subtitleText" class="font-weight-light" style="min-height:82px">
                {{ brand.subtitle }}
              </v-card-subtitle>
            </v-card-text>
            <br>
            <v-card-actions class="d-flex justify-center" style="position: absolute; bottom: 0; width: 100%;">
              <v-btn v-if="brand.url != null" color="cyan darken-1" text :href="brand.url || ''" target="_blank">
                {{ $t('message.base.others.visit') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  created() { },
  methods: {},
  computed: {
    titleText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "text-h5"
        case 'sm': return "text-h4"
        case 'md': return "text-h4"
        case 'lg': return "text-h4"
        case 'xl': return "text-h4"
      }
    },
    subtitleText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "text-subtitle-1"
        case 'sm': return "text-h6"
        case 'md': return "text-h6"
        case 'lg': return "text-h6"
        case 'xl': return "text-h6"
      }
    },
  }
}
</script>

<style scoped>
.v-application .text-h4 {
  font-weight: 300 !important;
  font-size: 22px !important;
}

.v-card--reveal {
  height: 100%;
  position: absolute;
  z-index: 1;
}

.v-img {
  z-index: 0;
}

.v-card-text {
  z-index: 2;
  position: relative;
  padding: 16px;
  flex-grow: 1;
}

.bg-white-text {
  background-color: #ffffff !important;
  color: #212121 !important;
}

.v-card-actions {
  z-index: 2;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 16px;
}
</style>
