<template>
    <v-container class="py-14" fluid>

        <v-row v-if="$t('message.status.news') == 'ok'" align="start" justify="center">

            <v-col cols="11" xl="8" lg="8" md="10" sm="11">
                <p :style="headerText">
                    {{ $t('message.news[0].title[0].title') }}
                </p>
            </v-col>

            <v-col v-for="(post, i) in $t('message.news[0].blogs')" :key="i" cols="12" xl="8" lg="8" md="10" sm="12">
                <v-card v-if="$route.params.id == post.title" outlined tile class="pa-4">
                    <v-row>
                        <v-col>
                            <v-btn
                                disabled
                                small
                                text
                                tile
                            >
                                <v-icon class="pr-2">mdi-clock</v-icon>
                                {{ post.created_at }}
                            </v-btn>
                            
                            <v-btn
                                disabled
                                small
                                text
                                tile
                            >
                                <v-icon class="pr-2">mdi-update</v-icon>
                                {{ post.updated_at }}
                            </v-btn>
                            
                            <v-btn
                                disabled
                                small
                                text
                                tile
                            >
                                <v-icon class="pr-2">mdi-account</v-icon>
                                {{ post.user.name }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card-title class="font-weight-light text-h4 px-2" style="word-break: break-word;"> {{ post.title }} </v-card-title>
                        </v-col>
                    </v-row>
                    
                    <v-row v-if="'https://clprocesos.cl/public/images/' + post.image_path">
                        <v-col>
                            <v-img
                            contain
                            class="pb-4"
                            max-height="300"
                            :src="'https://clprocesos.cl/public/images/' + post.image_path">
                            </v-img>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="text-body-1 font-weight-light px-2" style="text-align: justify">{{ post.body }}</div>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card v-if="$route.params.id == null" outlined tile class="pa-4">
                    <v-row>
                        <v-col>
                            <v-btn
                                disabled
                                small
                                text
                                tile
                            >
                                <v-icon class="pr-2">mdi-clock</v-icon>
                                {{ post.created_at }}
                            </v-btn>
                            
                            <v-btn
                                disabled
                                small
                                text
                                tile
                            >
                                <v-icon class="pr-2">mdi-update</v-icon>
                                {{ post.updated_at }}
                            </v-btn>
                            
                            <v-btn
                                disabled
                                small
                                text
                                tile
                            >
                                <v-icon class="pr-2">mdi-account</v-icon>
                                {{ post.user.name }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card-title class="font-weight-light text-h4 px-2"> {{ post.title }} </v-card-title>
                        </v-col>
                    </v-row>
                    
                    <v-row v-if="'https://clprocesos.cl/public/images/' + post.image_path">
                        <v-col>
                            <v-img
                            contain
                            class="pb-4"
                            max-height="300"
                            :src="'https://clprocesos.cl/public/images/' + post.image_path">
                            </v-img>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="text-body-1 font-weight-light px-2" style="text-align: justify">{{ post.body }}</div>
                        </v-col>
                    </v-row>
                    
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'News',
    data: () => ({}),
    created() {},
    methods: {},
    computed: {
      headerText () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "font-size: 20px; text-align: justify;"
          case 'sm': return "font-size: 30px; text-align: center;"
          case 'md': return "font-size: 30px; text-align: center;"
          case 'lg': return "font-size: 40px; text-align: center;"
          case 'xl': return "font-size: 40px; text-align: center;"
        }
      },
    },
  }
</script>
