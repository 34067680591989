import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Brands from '../views/Brands.vue'
import Products from '../views/Products.vue'
import Contact from '../views/Contact.vue'
import News from '../views/News.vue'
import Services from '../views/Services.vue'
import Product from '../views/Product.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/about',
        component: About
    },
    {
        path: '/brands',
        component: Brands
    },
    {
        name: 'products',
        path: '/products',
        component: Products
    },
    {
        name: 'contact',
        path: '/contact',
        component: Contact
    },
    {
        path: '/news/',
        component: News
    },
    {
        // Tenia que usar mismo componente con path distinto
        // El name tiene un espacio vacio al final por el breadcrumb
        path: '/news/:id',
        component: News
    },
    {
        path: '/services',
        component: Services
    },
    {
        // Actualmente no se esta usando
        path: '/products/:id',
        component: Product
    },
    {
        path: '*',
        component: PageNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
