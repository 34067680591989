<template>
    <v-app>
        <Navbar v-if="navbar" />
        <NavbarMobile v-if="mobile" @status="drawer = $event" />
        <NavDrawer @status="drawer = $event" :drawer="this.drawer" />
        <v-main class="grey lighten-5">
            <Breadcrumbs v-if="this.$route.path != '/'" />
            <router-view></router-view>
            <v-fab-transition>
            <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            :large="fabSize"
            dark
            fixed
            bottom
            right
            color="cyan"
            @click="toTop"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
          </v-fab-transition>
        </v-main>
        <Footer />
    </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import NavbarMobile from '@/components/NavbarMobile.vue'
import NavDrawer from '@/components/NavDrawer.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'App',
  components: { 
      Navbar,
      NavbarMobile,
      NavDrawer,
      Footer,
      Breadcrumbs,
      }, 
  data: () => ({
    //
    fab: false,
    drawer: false,
  }),
  computed: {
      navbar () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return false
          case 'sm': return false 
          case 'md': return true
          case 'lg': return true
          case 'xl': return true
        }
      },
      mobile () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return true 
          case 'md': return false
          case 'lg': return false
          case 'xl': return false
        }
      },
      fabSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return false
          case 'sm': return false 
          case 'md': return true
          case 'lg': return true
          case 'xl': return true
        }
      },
    },
  methods: {
      onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 300
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
}
</script>

