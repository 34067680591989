<template>
  <v-col cols="10" class="pt-15" align="center">
    <v-sheet style="align-self: center !important; padding: 3px;">
      <v-row no-gutters class="pt-2 m-4p" align="center" justify="center">
        <div class="black--text">
          <h1>{{ $t('message.carousel_products.title') }}</h1>
        </div>
      </v-row>

      <v-row no-gutters class="m-4p" align="center" justify="center">
        <div class="black--text">{{ $t('message.carousel_products.subtitle') }}</div>
      </v-row>

      <div class="carousel-container pt-6">
        <carousel  :per-page-custom="[[240, 1], [480, 1], [768, 2], [1024, 3]]" :loop="true"
          :pagination-enabled="true" hide-delimiters fade>
          <slide v-for="(item, index) in $t('message.carousel_products.products.items')" :key="index">
            <div class="card">
              <img :src="item.image_path" :alt="'Descripción ' + (index + 1)" />
              <h3>{{ item.title }}</h3>
              <p>{{ item.description }}</p>
              <button @click="solicitarInformacion(index + 1)">{{ item.btn_text }}</button>
            </div>
          </slide>
        </carousel>
      </div>

      <v-row no-gutters class="pt-2 m-4p m-section" align="center" justify="center">
        <div class="black--text btn-custom-e2">
          <button class="view-more" @click="navigateToProducts()">{{ $t('message.carousel_products.see_more_btn') }}</button>
        </div>
      </v-row>
    </v-sheet>
  </v-col>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
  },
  props: ['products'],
  data() {
    return {
      isMobile: window.innerWidth <= 480,
    };
  },
  methods: {
    solicitarInformacion(id) {
      console.log(`Solicitando información para el producto ${id}`);
      this.$router.push({ name: 'contact' });
    },
    navigateToProducts() {
			this.$router.push({ name: 'products' });
		},
    handleResize() {
      this.isMobile = window.innerWidth <= 480;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  padding: 0 30px;
}

.carousel-container .VueCarousel {
  overflow: visible;
}

.card {
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0px;
  text-align: center;
  margin: 0 10px;
  box-sizing: border-box;
}

.card img {
  width: 100%;
  object-fit: cover;
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
  min-height: 310px;
}

.card h3 {
  margin: 16px 0 8px;
  font-size: 18px;
}

.card p {
  margin: 0 0 16px;
  font-size: 14px;
  height: 60px;
  overflow: hidden;
}

.card button {
  background-color: #00BCD4;
  color: #000000;
  border: none;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.card button:hover {
  background-color: #007acc;
}

.VueCarousel-navigation-prev,
.VueCarousel-navigation-next {
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.VueCarousel-navigation-prev:hover,
.VueCarousel-navigation-next:hover {
  background-color: #f0f0f0;
}

.VueCarousel-navigation-prev::before,
.VueCarousel-navigation-next::before {
  font-size: 20px;
  color: #000;
}

.view-more {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.btn-custom-e2 {
  width: 220px;
  border: solid 3px #26C6DA;
  font-weight: 500;
  align-self: center !important;
  padding: 10px;
}

.m-section {
  margin: 30px 0px 30px 0px;
}


@media only screen and (max-width: 640px) {
  .card {
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0px;
    text-align: center;
    margin: 0px;
    box-sizing: border-box;
  }

  .carousel-container[data-v-5eca2d14] {
    padding: 0px;
  }

}

@media only screen and (max-width: 768px) and (min-width: 640px) {
  .card {
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0px;
    text-align: center;
    margin: 0px;
    box-sizing: border-box;
  }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) {}
</style>
