<template>
    <v-app-bar
      color="cyan"
      dark
      app
    >
      <v-app-bar-nav-icon @click="send"></v-app-bar-nav-icon>

      <v-toolbar-title>CL Procesos S.A.</v-toolbar-title>
    </v-app-bar>
</template>
<script>
  export default {
    data: () => ({
    }),
    methods: {
        send() {
            this.drawer = true
            this.$emit("status", this.drawer)
        }
    },
  }
</script>
