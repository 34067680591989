<template>
    <div class="title-and-timeline">
        <h2>{{ title }}</h2>
        <img class="image-desktop" :src="desktopImage" alt="Desktop Image">
        <img class="image-mobile" :src="mobileImage" alt="Mobile Image">
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        desktopImage: {
            type: String,
            required: true
        },
        mobileImage: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.title-and-timeline {
    text-align: -webkit-center;
    padding: 20px;
}

.image-desktop {
    display: block;
    padding: 3%;
    max-width: 1080px;

}

.image-mobile {
    display: none;
    max-width: 360px;
    padding: 3%;

}


/* Media Queries para cambiar la visibilidad */
@media (max-width: 640px) {
    .image-desktop {
        display: none;
    }

    .image-mobile {
        display: block;
        padding: 0%;
        margin-top: 20px;
    }
}
</style>