<template>
    <div class="main-text-and-cards">
        <h2>{{ title }}</h2>
        <h4>{{ subtitle }}</h4>
        <div class="cards-container">
            <div class="card" v-for="(card, index) in cards" :key="index"
                :style="{ backgroundImage: 'url(' + card.image + ')' }">
                <div class="card-content cardContentCustom">
                    <h3 :class="['mg-c', index === 1 ? 'mt-c' : '']">{{ card.title }}</h3>
                    <p :class="[ index === 1 ? 's-1' : '']" v-html="card.text"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        cards: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
.main-text-and-cards {
    text-align: center;
    padding: 70px;
}

.mg-c{
    padding: 2%;
    margin-top: 5px;
}

.cards-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3%;
}

.card {
    background-size: contain;
    background-position: center;
    padding: 35px;
    margin: 10px;
    width: 30%;
    color: #000;
}

.card-content {
    padding: 18px;
    margin-top: 55px;
}

/* Media Queries para Responsividad */

@media (max-width: 1392px) {
    p {
        font-size: 14px;
    }
}

@media (max-width: 1265px) {
    p {
        font-size: 13px;
    }
}

@media (max-width: 1208px) {
    p {
        font-size: 12px;
    }
}

@media (max-width: 1152px) {
    p {
        font-size: 11px;
    }
}

@media (max-width: 1024px) {
    .card {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .card {
        padding: 0px;
        width: 100%;
        margin: 5px;
    }
}

@media only screen and (max-width: 640px) {
    .row {
      margin: 0px;
    }

    .mg-c{
        padding-top: 24px;
    }

    .cardContentCustom {
        padding: 13px !important;
        margin-top: 40px;
        font-size: 16px;
    }
  
    .main-text-and-cards{
      padding: 15px;
    }

    .card {
        padding: 20px;
        width: 100%;
        margin: 5px;
    }

    .s-1{
        font-size: 11px;
    }
  
  }
</style>