<template>
  <v-footer padless>
    <v-row class="cyan darken-1 white--text text-center py-4 px-8 justify-content">
      <!-- Contact Information -->
      <v-col cols="12" md="4" class="text-left">
        <v-row align="center" class="mb-4">
          <v-col cols="2">
            <v-icon class="white--text" size="32px">mdi-phone</v-icon>
          </v-col>
          <v-col cols="10">
            <p class="mb-0">Santiago +56 (2) 2929 5477</p>
          </v-col>
        </v-row>
        <v-row align="center" class="mb-4">
          <v-col cols="2">
            <v-icon class="white--text" size="32px">mdi-email</v-icon>
          </v-col>
          <v-col cols="10">
            <p class="mb-0">cl.contacto@clprocesos.cl</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="2">
            <v-icon class="white--text" size="32px">mdi-map-marker</v-icon>
          </v-col>
          <v-col cols="10">
            <p class="mb-0">Av. Irarrázaval 3475 Of. 1 Ñuñoa, Región Metropolitana de Santiago - Chile.</p>
          </v-col>
        </v-row>
      </v-col>

      <!-- Menu -->
      <v-col cols="12" md="2" class="text-left">
        <p class="font-weight-bold text-h6 mb-2">{{ $t('message.footer.menu_title') }}</p>
        <v-list-item-group>
          <v-list-item>
            <router-link to="/" class="v-list-item--link">
              <v-list-item-content>
                <v-list-item-title>{{ $t('message.footer.home') }}</v-list-item-title>
              </v-list-item-content>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link to="/products" class="v-list-item--link">
              <v-list-item-content>
                <v-list-item-title>{{ $t('message.footer.products') }}</v-list-item-title>
              </v-list-item-content>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link to="/services" class="v-list-item--link">
              <v-list-item-content>
                <v-list-item-title>{{ $t('message.footer.services') }}</v-list-item-title>
              </v-list-item-content>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link to="/brands" class="v-list-item--link">
              <v-list-item-content>
                <v-list-item-title>{{ $t('message.footer.representations') }}</v-list-item-title>
              </v-list-item-content>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link to="/about" class="v-list-item--link">
              <v-list-item-content>
                <v-list-item-title>{{ $t('message.footer.about_us') }}</v-list-item-title>
              </v-list-item-content>
            </router-link>
          </v-list-item>
        </v-list-item-group>
      </v-col>

      <!-- Contact Form and Social Media -->
      <v-col cols="12" md="4" class="text-left">
        <p class="font-weight-bold text-h6 mb-2">{{ $t('message.footer.contact_title') }}</p>
        <v-form class="form-contact" @submit.prevent="submitEmail">
          <v-text-field v-model="email" :label="$t('message.footer.email_placeholder')" class="buttom-contact"
            required></v-text-field>
          <v-btn type="submit" color="primary buttom-size">{{ $t('message.footer.send_btn') }}</v-btn>
        </v-form>
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <p class="font-weight-bold text-h6 mt-4" hidden>{{ $t('message.footer.follow_us') }}</p>
        <v-row justify="start" style="display: none;">
          <v-col cols="1" class="icon-social">
            <a href="https://www.instagram.com/tu_perfil" target="_blank" rel="noopener noreferrer">
              <v-icon size="32px">mdi-instagram</v-icon>
            </a>
          </v-col>
          <v-col cols="1" class="icon-social">
            <a href="https://www.facebook.com/tu_perfil" target="_blank" rel="noopener noreferrer">
              <v-icon size="32px">mdi-facebook</v-icon>
            </a>
          </v-col>
          <v-col cols="1" class="icon-social">
            <a href="https://www.linkedin.com/company/cl-procesos-s.a./about/" target="_blank"
              rel="noopener noreferrer">
              <v-icon size="32px">mdi-linkedin</v-icon>
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row class="cyan darken-1 white--text text-center py-4 ">
      <v-col cols="12 justify-content">
        <v-img src="@/assets/logo.png" max-height="65" max-width="156"></v-img>
        <p>CL Procesos S.A. {{ new Date().getFullYear() }} {{ $t('message.footer.rights_reserved') }}</p>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      icons: ["mdi-phone", "mdi-email", "mdi-map-marker"],
      email: '',
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    async submitEmail() {
      try {
        const response = await fetch('https://e2g2e55uka.execute-api.us-east-1.amazonaws.com/send_email_cl_proceso', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: this.email }),
        });

        if (response.ok) {
          const data = await response.json();
          this.successMessage = this.$t('message.footer.success_message');
          this.email = ''; // Limpiar el campo de email
          setTimeout(() => {
            this.successMessage = ''; // Ocultar mensaje después de unos segundos
            window.location.reload(); // Recargar la página
          }, 2000);
        } else {
          console.error('Error en la respuesta del servidor:', response.statusText);
          this.errorMessage = this.$t('message.footer.error_message');
        }
      } catch (error) {
        console.error('Error al enviar el correo', error);
        this.errorMessage = this.$t('message.footer.error_message');
      }
    },
  },
};
</script>


<style scoped>
.success-message {
  color: green;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.v-footer {
  background-color: #0097a7;
  display: grid;
}

.justify-content {
  justify-content: center;
  text-align: -webkit-center;
}

.buttom-size {
  width: 150px;
  height: 44px !important;

}

.v-divider {
  clip-path: border-box;
  color: rgba(46, 46, 46, 0.54);
  border: solid 0.3px;
}

.buttom-contact {
  background: #FFF;
  height: 45px;
}

.form-contact {
  display: inline-flex;
}

.v-text-field {
  margin-top: 0px;
}

.v-icon {
  color: #FFF;
}

.v-list-item--link {
  padding: 0px;
}

.v-list-item__title {
  color: #FFF;
}

.v-application a {
  color: #00acc1;
}

@media only screen and (max-width: 640px) {
  .row {
    margin: 0px;
  }

  .icon-social {
    padding: 40px;
  }
}
</style>