<template>
    <div class="wide-image">
        <img :src="imageSrc" alt="Wide Image">
    </div>
</template>

<script>
export default {
    props: {
        imageSrc: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.wide-image img {
    width: 100%;
    height: auto;
}
</style>