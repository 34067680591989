<template>
	<v-container class="ma-0 px-0 pb-16 bg-white" fluid>
		<Carousel v-bind:carousel="carousel" />
		<v-row class="py-10" no-gutters style="place-content: center;">
			<!-- Frame 1 -->
			<v-col cols="12" lg="6" md="6" class="hidden-sm-and-down">
				<v-sheet color="grey lighten-5" height="500">
					<v-img class="fill-heigh-custom" src="@/assets/onboard.jpg"></v-img>
				</v-sheet>
			</v-col>

			<!-- Frame 2 -->
			<v-col cols="12" lg="6" md="6">
				<v-sheet color="cyan darken-1" height="500">
					<v-row no-gutters class="pt-15 pb-4">
						<v-col cols="1"></v-col>
						<v-col fluid>
							<div class="title-h4 white--text" style="font-weight: 700;">
								{{ $t('message.base.home.text_1') }}
							</div>
						</v-col>
						<v-col cols="1"></v-col>
					</v-row>

					<v-row no-gutters class="pb-4">
						<v-col cols="1"></v-col>
						<v-col fluid>
							<div align="left" :class="titleText" class="text-h6 text-h6-custom white--text">
								<span v-html="$t('message.base.home.text_d_1')"></span>
							</div>
						</v-col>
						<v-col cols="1"></v-col>
					</v-row>

					<v-row no-gutters class="pt-15 pb-4">
						<v-col cols="1"></v-col>
						<v-col fluid>
							<v-row no-gutters class="pt-2" align="center">

								<div class="white--text btn-custom-e">{{ $t('message.base.home.text_b_1') }}</div>
								<v-btn to="/contact" class="circle">
									<v-icon large style="color: #00ACC1">mdi-chevron-right</v-icon>
								</v-btn>
							</v-row>

						</v-col>
					</v-row>
				</v-sheet>
			</v-col>

		</v-row>

		<!-- Frame 3 -->
		<v-row class="py-10" no-gutters style="place-content: center; background-color:white;">
			<CarouselProducts :products="carousel_products" />
		</v-row>

		<!-- Frame 4 -->
		<!-- <ContactForm /> -->

		<LogoCarousel v-bind:carousel="carouselLogo" />
	</v-container>

</template>

<script>
import Carousel from "@/components/Carousel.vue"
import CarouselProducts from "../components/CarouselProducts.vue";
import ContactForm from "../components/ContactForm.vue";
import LogoCarousel from "../components/LogoCarousel.vue";

export default {
	name: "Home",
	components: {
		Carousel,
		CarouselProducts,
		ContactForm,
		LogoCarousel,
	},
	data() {
		return {
			carousel: {},
			carouselLogo: {},
			carousel_products: {},
		}
	},
	created() { },
	methods: {},
	computed: {
		titleText() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "text-h5"
				case 'sm': return "text-h4"
				case 'md': return "text-h4"
				case 'lg': return "text-h4"
				case 'xl': return "text-h4"
			}
		},
	}
}


</script>

<style scoped>
.circle {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	width: 36px;
	min-width: 36px !important;
}

.m-4p {
	margin: 4%;
}

.btn-custom-e {
	width: 260px;
	border: solid 3px white;
	font-weight: 500;
	align-self: center !important;
	padding: 10px;
}

.title-h4 {
	white-space: pre;
	font-weight: 200;
	font-size: 30px;
}

.text-h6-custom {
	white-space: pre;
	font-weight: 400;
}

.text-h6-custom {
	white-space: pre;
	font-weight: 400;
}

.v-application .py-10 {
	padding-bottom: 0px !important;
}

@media only screen and (max-width: 768px) {
	.text-h6-custom {
		font-size: 11px !important;
	}
}

.fill-heigh-custom {
	height: 100% !important;
	max-height: fit-content;
}

.bg-white {
	background: #FFF;
}

@media only screen and (max-width: 640px) {
	body {
		max-width: 350px;
	}

}
</style>
