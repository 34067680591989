<template>
    <div class="contact-form-container">
        <div class="text-container">
            <h1>{{ $t('message.contact_form.title') }}</h1>
            <h3>{{ $t('message.contact_form.subtitle') }}</h3>
        </div>
        <div class="contact-form">
            <form @submit.prevent="submitForm">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field :label="$t('message.contact_form.name_label')" v-model="form.name"
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :label="$t('message.contact_form.surname_label')" v-model="form.surname"
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :label="$t('message.contact_form.company_label')" v-model="form.company"
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :label="$t('message.contact_form.phone_label')" v-model="form.phone"
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :label="$t('message.contact_form.email_label')" v-model="form.email"
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :label="$t('message.contact_form.product_label')"
                            v-model="form.productOrService"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea :label="$t('message.contact_form.message_label')" v-model="form.message"
                            rows="4"></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox label-class="fn-12" v-model="form.agree"
                            :label="$t('message.contact_form.agree_label')" required></v-checkbox>
                    </v-col>
                    <v-col cols="12 m-c">
                        <v-btn type="submit" color="primary">{{ $t('message.contact_form.submit_btn') }}</v-btn>
                    </v-col>
                    <v-col cols="12" style="color: black;">
                        <p>{{ $t('message.contact_form.required_fields_note') }}</p>
                    </v-col>
                </v-row>
            </form>
        </div>

        <div v-if="showModal" class="modal-overlay">
            <div class="modal-content">
                <p v-if="modalMessage">{{ modalMessage }}</p>
                <p v-else>Error desconocido</p>
                <v-btn color="primary" @click="closeModal">Aceptar</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                company: '',
                surname: '',
                name: '',
                phone: '',
                email: '',
                productOrService: '',
                message: '',
                agree: false,
            },
            showModal: false,
            modalMessage: '',
        };
    },
    methods: {
        async submitForm() {
            const maxRetries = 3; // Número máximo de reintentos
            let retries = 0;
            let success = false; // Bandera para indicar si la solicitud fue exitosa

            while (retries < maxRetries && !success) {
                try {
                    const response = await fetch('https://e2g2e55uka.execute-api.us-east-1.amazonaws.com/send_email_cl_proceso', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(this.form),
                    });

                    if (response.ok) {
                        success = true; // Marcar como exitoso
                        this.modalMessage = this.$t('message.contact_form.success_message');
                        console.log("Mensaje de éxito:", this.modalMessage);
                    } else {
                        console.log("Error del servidor. Reintentando...");
                    }
                } catch (error) {
                    console.error('Error al enviar el formulario', error);
                }

                retries++;
                if (retries < maxRetries && !success) {
                    console.log(`Reintentando... (${retries}/${maxRetries})`);
                    await new Promise(resolve => setTimeout(resolve, 2000)); // Esperar 2 segundos antes de reintentar
                }
            }

            // Mostrar el mensaje de error solo si todos los reintentos fallaron
            if (!success) {
                this.modalMessage = this.$t('message.contact_form.error_message');
                console.log("Error final: Todos los reintentos fallaron.");
            }

            this.showModal = true; // Mostrar la modal con el mensaje final
        }

        // async submitForm() {
        //     try {
        //         const response = await fetch('https://e2g2e55uka.execute-api.us-east-1.amazonaws.com/send_email_cl_proceso', {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify(this.form),
        //         });

        //         if (response.ok) {
        //             this.modalMessage = this.$t('message.contact_form.success_message');
        //             console.log("Mensaje de éxito:", this.modalMessage); // Depuración
        //         } else {
        //             this.modalMessage = this.$t('message.contact_form.error_message');
        //             console.log("Mensaje de error del servidor:", this.modalMessage); // Depuración
        //         }
        //     } catch (error) {
        //         console.error('Error al enviar el formulario', error);
        //         this.modalMessage = this.$t('message.contact_form.error_message');
        //         console.log("Error de catch:", this.modalMessage); // Depuración
        //     }

        //     this.showModal = true;
        // },
        // closeModal() {
        //     this.showModal = false;
        //     window.location.reload();
        // }
    }
};
</script>

<style scoped>
.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    background-image: url('/images/form-img.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 20px;
    color: #fff;
}

.text-container {
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20%;
}

.contact-form {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    margin-left: 20%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: #000;
    /* Asegura que el texto sea negro para mejor contraste */
}

@media (max-width: 768px) {
    .contact-form {
        width: 70;
    }

    .m-c {
        text-align: center;
    }

    p {
        font-size: 12px !important;
    }

    div {
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 640px) {
    .contact-form {
        width: 90%;
        margin-left: 5%;
    }

    .text-container {
        margin-left: 5%;
    }

    .v-text-field {
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

    .col-12 {
        padding: 4px;
    }

    .m-c {
        text-align: center;
    }

    .fn-12 {
        font-size: 9px;
    }
}
</style>
