import axios from 'axios'

axios.defaults.baseURL = 'https://clprocesos.cl/clprocesos-api/api/v1/'

var message = {
    base: {
        language: "Language",
        menu: {
            slogan: "Applied\n Technology",
            home: "ホーム",
            products: "Products",
            services: "Services",
            brands: "Brands",
            about: "About us",
            contact: "Contact",
            others: {
                visit: "Go to the website",
            },
        },
        breadcrumbs: {
            home: "Home",
            news: "News",
            products: "Products",
            services: "Services",
            brands: "Brands",
            about: "About us",
            contact: "Contact",
        },
        carousel: {
            news: "News",
            loading: "Loading news...",
            all_news: "See all the news",
        },
        footer: {
            products: "Products",
            brands: "Brands",
            copyright: "All rights reserved",
        },
        home: {
            text_1: "Projects and services",
            text_2: "We respond effectively\n to the needs on-site",
            text_3: "Products",
            text_4: "Products with high quality standards",
        },
        contact: {
            address: "Address",
            phone: "Telephone",
            postal_code: "Postal Code",
            email: "Contact Email",
        },
    },
    products: null,
    about: null,
    services: null,
    brands: null,
    contact: null,
    carousel: null,
    news: null,
    status: {
        products: "not ok",
        about: "not ok",
        services: "not ok",
        brands: "not ok",
        contact: "not ok",
        carousel: "not ok",
        news: "not ok",
    },
}

axios.get('product').then(response => {
    message.products = response.data
    message.status.products = "ok"
})

axios.get('about').then(response => {
    message.about = response.data[0]
    message.status.about = "ok"
})

axios.get('service').then(response => {
    message.services = response.data[0]
    message.status.services = "ok"
})

axios.get('brand').then(response => {
    message.brands = response.data
    message.status.brands = "ok"
})

axios.get('contact').then(response => {
    message.contact = response.data
    message.status.contact = "ok"
})

axios.get('carousel').then(response => {
    message.carousel = response.data
    message.status.carousel = "ok"
})

axios.get('blog').then(response => {
    message.news = response.data
    message.status.news = "ok"
})

export default { message }