<template>
  <div>
    <v-row  class="v-c-hdp">
      <v-col cols="12" md="6" class="n-pd">
        <v-card-text class="p-100-l-r">
          <h2 class="font-weight-bold">{{ $t('message.banner_services.title') }}</h2>
          <br>
          <p class="subtitle-1">{{ $t('message.banner_services.subtitle') }}</p>
        </v-card-text>
      </v-col>
      <v-col cols="12" md="6" class="v-hdp-p">
        <v-img :src="bannerImage" aspect-ratio="1.5" class="hdp-img"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerImage: '/images/banner_service.png',
    };
  },
};
</script>

<style scoped>
.font-weight-bold {
  font-size: 2rem;
}

.subtitle-1 {
  font-size: 1.25rem;
}

.p-100-l-r {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 100px;
}

@media only screen and (max-width: 640px) {
  .row {
    margin: 0px;

  }

  .p-100-l-r {
    padding-left: 20px;
    padding-right: 20px;
  }

  .font-weight-bold {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .n-pd {
    padding: 0;
    max-width: 363px;
  }

}


@media (max-width: 1992px) {
  .v-c-hdp {
    max-height: 400px;
    height: 320px;
    background-color: #E5E5E5;
  }

  .hdp-img{
      max-height: 320px !important;
      height: 308px;
      background-size: contain !important;
  }

  .v-hdp-p{
    padding: 0px !important;
  }
}

@media (max-width: 1392px) {
  .v-c-hdp {
    max-height: 400px;
    height: 320px;
    background-color: #E5E5E5;
  }
  .hdp-img{
    max-height: 320px !important;
  }
}

@media (max-width: 1265px) {
  .v-c-hdp {
    max-height: 400px;
    height: 320px;
    background-color: #E5E5E5;
  }
  .hdp-img{
    max-height: 320px !important;
  }
}

@media (max-width: 1208px) {
  .v-c-hdp {
    max-height: 400px;
    height: 320px;
    background-color: #E5E5E5;
  }
  .hdp-img{
    max-height: 320px !important;
  }
}

@media (max-width: 1152px) {
  .v-c-hdp {
    max-height: 400px;
    height: 320px;
    background-color: #E5E5E5;
  }
  .hdp-img{
    max-height: 320px !important;
  }
}

@media (max-width: 1024px) {
  .v-c-hdp {
    max-height: 400px;
    height: 320px;
    background-color: #E5E5E5;
  }
  .hdp-img{
    max-height: 320px !important;
  }
}
</style>