<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
      </div>
</template>
<script>
  export default {
    data: () => ({
      breadcrumb: null,
      items: [
        {
          text: null,
          disabled: false,
          to: '/',
        },
      ],
    }),
    created() {
        this.breadcrumbs()
        this.pushBreadcrumbs()
        },
    watch: {
        $route (to, from) {
        this.updateBreadcrumbs()
        this.breadcrumbs()
        this.pushBreadcrumbs()
        }
    },
    methods: {
        pushBreadcrumbs() {
            let item = {}
            item.text = this.breadcrumb
            item.disabled = true
            item.exact = true
            item.to = this.$route.path.replace(this.$route.params.id, '')
            this.items.push(item)

            if (this.$route.params.id) {
                this.items[1].disabled = false
                let item = {}
                item.text = this.$route.params.id
                item.disabled = true
                item.exact = true
                item.to = this.$route.path
                this.items.push(item)
            }
        },
        updateBreadcrumbs() {
            this.items.splice(1, this.items.length - 1)
        },
        breadcrumbs() {

          this.items[0].text = this.$t('message.base.breadcrumbs.home')

          if (this.$route.path.startsWith('/services')) {
            this.breadcrumb = this.$t('message.base.breadcrumbs.services')
          }

          if (this.$route.path.startsWith('/news')) {
            this.breadcrumb = this.$t('message.base.breadcrumbs.news')
          }

          if (this.$route.path.startsWith('/products')) {
            this.breadcrumb = this.$t('message.base.breadcrumbs.products')
          }

          if (this.$route.path.startsWith('/brands')) {
            this.breadcrumb = this.$t('message.base.breadcrumbs.brands')
          }

          if (this.$route.path.startsWith('/contact')) {
            this.breadcrumb = this.$t('message.base.breadcrumbs.contact')
          }

          if (this.$route.path.startsWith('/about')) {
            this.breadcrumb = this.$t('message.base.breadcrumbs.about')
          }
        }
    },
  }
</script>
