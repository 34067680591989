<template>
    <v-app-bar app height="100px" color="white">
      <router-link to="/">
        <v-img lazy-src="@/assets/logo.png" src="@/assets/logo.png"></v-img>
      </router-link>
      <v-tabs centered color="darken-4" class="custom-tabs">
        <v-tab to="/" class="custom-tab"> {{ $t('message.base.menu.home') }} </v-tab>
        <v-tab to="/products" class="custom-tab"> {{ $t('message.base.menu.products') }} </v-tab>
        <v-tab to="/services" class="custom-tab"> {{ $t('message.base.menu.services') }} </v-tab>
        <v-tab to="/brands" class="custom-tab"> {{ $t('message.base.menu.brands') }} </v-tab>
        <v-tab to="/about" class="custom-tab"> {{ $t('message.base.menu.about') }} </v-tab>
        <v-tab to="/contact" class="custom-tab"> {{ $t('message.base.menu.contact') }} </v-tab>
      </v-tabs>
      <v-col cols="2">
        <v-select 
          v-model="setLocale" v-on:change="changeLanguage"
          :items="locale"
          append-outer-icon="mdi-earth"
          menu-props="auto"
          hide-details
          :label="$t('message.base.language')"
          single-line
        >
        </v-select>
      </v-col>
    </v-app-bar>
  </template>
  

<script>
export default {
    data() {
        return {
            setLocale: null,
            locale: ['Español', 'English'],
        }
    },
    methods: {
        changeLanguage() {
            if (this.setLocale == 'Español') {
                this.$i18n.locale = 'es'
            }
            if (this.setLocale == 'English') {
                this.$i18n.locale = 'en'
            }
            if (this.setLocale == '日本語') {
                this.$i18n.locale = 'ja'
            }
        },
    },
}
</script>



