import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import english from './lang/en.js'
import spanish from './lang/es.js'
import japanese from './lang/ja.js'
import VueI18n from 'vue-i18n'
 
Vue.use(VueAxios, axios)
Vue.use(VueI18n)

// API URL
axios.defaults.baseURL = 'https://clprocesos.cl/clprocesos-api/api/v1/'

// Ready translated locale messages
const messages = {
  en: english,
  es: spanish,
  ja: japanese,
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'es', // set locale
  fallbackLocale: 'es',
  messages // set locale messages
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
