<template>
	<v-container fluid class="ma-0 pa-0">
		<v-row justify="center carousel-mobile">
			<v-col cols="12" sm="12" md="12" lg="12" class="my-0 py-0 carousel-mobile-r">
				<v-carousel v-if="$t('message.status.carousel') == 'ok'" cycle :height="height" hide-delimiters fade
					show-arrows-on-hover>
					<v-carousel-item class="imag-back-mobile" eager v-for="(slide, i) in $t('message.carousel')"
						:key="i" :src="slide.image_path">
						<v-row class="fill-height ">
							<v-col cols="12" sm="8" md="6" lg="5" xl="4" class="white--text text-left description-c-m">
								<h2 class="title" style="text-shadow: 2px 5px 4px rgba(0, 0, 0, 0.6);">
									{{ slide.title }}
								</h2>
								<p class="description" style="text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.4);">
									{{ slide.description }}
								</p>
								<div class="btn-container">
									<v-btn class="btn-slider" style="background: #00ACC1; color:white"
										@click="navigateToSlide(slide.link)">
										{{ slide.btn_text }}
									</v-btn>
									<div class="circle">
										<v-icon large style="color: #00ACC1">mdi-chevron-right</v-icon>
									</div>
								</div>
							</v-col>
						</v-row>
					</v-carousel-item>
				</v-carousel>
			</v-col>

			<!-- Blog news -->
			<v-col cols="12" sm="9" md="6" lg="6" xl="6" class="pt-0 mx-0 px-0 news-mobile" :class="newsCol">
				<v-card elevation="3" tile>
					<v-row class="ma-0 pa-0">
						<v-col cols="12" lg="3"
							class="cyan darken-1 white--text ma-0 pa-0 d-flex align-center justify-center">
							<div class="text-h5" :class="newsBox1">{{ $t('message.base.carousel.news') }}</div>
						</v-col>
						<v-col cols="12" lg="9" class="ma-0 pa-0">
							<v-col :class="newsBox2">
								<router-link v-if="$t('message.status.news') == 'ok' && $t('message.news[0].blogs[0]')"
									style="text-decoration: none; color: inherit;"
									:to="'/news/' + $t('message.news[0].blogs[0].title')" custom v-slot="{ navigate }">
									<p v-if="$t('message.status.news') == 'ok'" style="cursor: pointer;"
										@click="navigate" role="link" class="pl-2"> {{
											$t('message.news[0].blogs[0].title') }} </p>
								</router-link>
								<p class="pl-2" v-else>{{ $t('message.base.carousel.loading') }}</p>
							</v-col>
							<v-col :class="newsBox2" style="border-top: 1px solid grey">
								<router-link v-if="$t('message.status.news') == 'ok' && $t('message.news[0].blogs[1]')"
									style="text-decoration: none; color: inherit;"
									:to="'/news/' + $t('message.news[0].blogs[1].title')" custom v-slot="{ navigate }">
									<p v-if="$t('message.status.news') == 'ok'" style="cursor: pointer;"
										@click="navigate" role="link" class="pl-2"> {{
											$t('message.news[0].blogs[1].title') }} </p>
								</router-link>
								<p class="pl-2" v-else>
									{{ $t('message.base.carousel.loading') }}
								</p>
							</v-col>
						</v-col>
					</v-row>
				</v-card>
				<v-col class="text-right px-0">
					<router-link to="/news" custom v-slot="{ navigate }">
						<v-btn @click="navigate" role="link" plain tile class="pt-4 mb-0 px-2">
							{{ $t('message.base.carousel.all_news') }}
							<v-icon large> mdi-chevron-right </v-icon>
						</v-btn>
					</router-link>
				</v-col>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "Carousel",
	props: ['carousel'],
	data() {
		return {
			slides: this.carousel.slides,
			news: this.carousel.news,
		}
	},
	computed: {
		slideText() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "text-h5"
				case 'sm': return "text-h4"
				case 'md': return "text-h2"
				case 'lg': return "text-h1"
				case 'xl': return "text-h1"
			}
		},
		height() {
			return this.$vuetify.breakpoint.width < 640 ? 'auto' : this.$vuetify.breakpoint.width * 0.3;
		},
		newsCol() {
			return this.$vuetify.breakpoint.name === 'xs' ? 'my-4' : 'mt-n13';
		},
		newsBox1() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "pa-3"
				case 'sm': return "ma-2"
				case 'md': return "ma-2 pa-2"
				case 'lg': return "ma-4 pa-4"
				case 'xl': return "ma-4 pa-4"
			}
		},
		newsBox2() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "pb-0"
				case 'sm': return "px-0 pb-0"
				case 'md': return "px-0 pb-0"
				case 'lg': return "px-0 pb-0"
				case 'xl': return "px-0 pb-0"
			}
		},
	},
	methods: {
		navigateToSlide(link) {
			this.$router.push({ name: 'products' });
		}
	}
}
</script>

<style scoped>
.v-image {
	max-height: 450px;
}

.carousel-item-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	text-align: left;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.5);
}

.carousel-item-content .title {
	font-size: 1.5rem;
	margin-bottom: 10px;
}

.carousel-item-content .description {
	font-size: 1rem;
	margin-bottom: 20px;
}

.carousel-item-content .v-btn {
	align-self: flex-start;
	font-size: 1rem;
	padding: 10px 20px;
}

.v-application .text-left {
	padding: 10%;
}

.v-application .title {
	font-size: 33px !important;
}

.v-application p {
	margin-top: 20px;
}

.btn-slider {
	border-radius: 0%;
	height: 50px !important;
	width: 100%;
	font-size: 1rem;
}

.btn-container {
	display: flex;
	flex-direction: row;
	align-items: stretch;
}

.v-application .mt-n13 {
	margin-top: -5% !important;
}

.description-c-m {
	padding-top: 6% !important;
}

.circle {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
}

@media only screen and (max-width: 640px) {
	.news-mobile {
		display: block !important;
		padding-top: 0px !important;
		max-width: 350px;
	}

	.carousel-mobile {
		height: auto;
	}

	.imag-back-mobile {
		height: 250px;
	}

	.v-application .text-left {
		padding: 5%;
		min-height: 240px;
	}

	.circle {
		display: none;
	}

	.carousel-mobile-r {
		min-height: 150px;
		max-width: 373px;
		margin: 0px;
		padding: 0px;
	}

	.row {
		margin: 0px;
	}

	.v-btn:not(.v-btn--round).v-size--default {
		padding: 0px;
		max-width: 360px;
	}

	.v-application p {
		max-width: 280px;
	}

	.v-application .title[data-v-e3565ce0] {
		font-size: 28px !important;
		max-width: 340px;
	}

}

@media only screen and (max-width: 768px) and (min-width: 640px) {
	.news-mobile {
		display: block !important;
		padding-top: 100px !important;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
	.news-mobile {
		display: block !important;
		padding-top: 100px !important;
	}
}

@media only screen and (min-width: 1280px) {}
</style>
